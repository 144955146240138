import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Layout, Menu, Avatar, Dropdown, Tabs } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { removeToken } from '~/utils'
import { Loading } from '~/components'
import { userStore, appStore } from '~/stores'
import { useLocation } from 'react-router-dom'
import { onGlobalStateChange, offGlobalStateChange } from '~/utils/subRegister'
import { MainSider } from '~/components/layouts/MainSider'
import styled from 'styled-components'
import { goto } from '~/utils'
import { color } from '~/configs/styles'
import { useMount } from 'react-use'

import { observer } from 'mobx-react'
import { useTitle } from '~/hooks/useTitle'
import { useInSubApp } from '~/hooks/useInSubApp'

const { Header, Content } = Layout
const { TabPane } = Tabs

interface MainBodyProps {
  collapsed: boolean
}

const MainApp = styled(Layout)`
  &&& {
    width: 100%;
    min-height: 100%;
  }
`

const MainBody = styled.div<MainBodyProps>`
  &&& {
    overflow-x: hidden;
    min-height: 100%;
    display: flex;
    flex: auto;
    flex-direction: column;
    padding-left: ${(props) => (props.collapsed ? '80px' : '200px')};
  }
`

const MainHeader = styled(Header)`
  &&& {
    background: #fff;
    display: flex;
    padding: 0;
    .header-left {
      flex: 1 1 0%;
    }
    .header-right {
      display: flex;
      float: right;
      padding-right: 24px;
    }
    .menu-trigger {
      font-size: 18px;
      line-height: 64px;
      padding: 0 24px;
      cursor: pointer;
      transition: color 0.3s;
    }
  }
`

const MainTabs = styled(Tabs)`
  &&& {
    background-color: #fff;
    border-top: 1px solid #f4f4f4;
    .ant-tabs-nav {
      margin: 0;
      &::before {
        display: none;
      }
    }
    .ant-tabs-tab-active {
      background: rgba(64, 169, 255, 0.2);
      border-color: rgba(64, 169, 255, 0.2);
      .ant-tabs-tab-remove {
        color: ${color.primary};
      }
    }

    .ant-tabs-tab {
      border-radius: 0;
      border-top: none;
      border-bottom: none;
      border-left: none;
      border-right: 1px solid #f4f4f4;
      margin-right: 0 !important;
      &:first-child .ant-tabs-tab-remove {
        display: none;
      }
    }
  }
`

const MainContainer = styled(Content)`
  &&& {
    margin: 24px 16px;
    padding: 24px;
    min-height: 500px;
    background: #fff;
  }
`

export const MainLayout: React.FC = observer((props) => {
  const location = useLocation()
  const { setTitle } = useTitle()
  const { inSubApp } = useInSubApp()

  const [collapsed, setCollapsed] = useState(false)

  const logout = useCallback(() => {
    removeToken()
    // 备注 为了修复在退出登录后，控制台一直报警告的问题
    // qiankun Set window.event while sandbox destroyed or inactive
    // history.replace('/login')
    window.location.href = '/login'
  }, [])

  const gotoSetting = useCallback(() => {
    window.open(`${process.env.BTC8_HOST}/user/settings/security`)
  }, [])

  const userMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="username" disabled>
          {userStore.userinfo.username}
        </Menu.Item>
        <Menu.Item key="setting" onClick={() => gotoSetting()}>
          个人设置
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => logout()}>
          退出登录
        </Menu.Item>
      </Menu>
    ),
    [logout, gotoSetting]
  )

  const activeKey = useMemo(() => {
    return location.pathname
  }, [location])

  const onTabChange = useCallback((key) => {
    const currentQuick = appStore.quickEntry.find((item) => item.key === key)

    currentQuick?.key
      ? goto(currentQuick.title, currentQuick.key)
      : goto('首页', '/')
  }, [])

  const onTabEdit = useCallback(
    (targetKey, action) => {
      const currentQuick = appStore.quickEntry.find(
        (item) => item.key === targetKey
      )

      if (currentQuick?.key && action === 'remove') {
        appStore.delQuickEntryItem({
          title: currentQuick.title,
          key: currentQuick?.key,
        })

        if (currentQuick?.key === activeKey) {
          const prevQuick = appStore.quickEntry[appStore.quickEntry.length - 1]

          prevQuick?.key
            ? goto(prevQuick.title, prevQuick.key)
            : goto('首页', '/')
        }
      }
    },
    [activeKey]
  )

  useEffect(() => {
    onGlobalStateChange((state, prev) => {
      console.log(state, prev, 'onGlobalStateChange')
      if (state.title !== prev.title) {
        setTitle(state.title)
      }
    })

    !inSubApp && setTitle()

    return () => {
      offGlobalStateChange()
    }
  }, [location, setTitle, inSubApp])

  useMount(() => {
    appStore.initQuickEntry()

    userStore.permissionMenus.forEach((allMenu) => {
      allMenu.children?.forEach((item) => {
        if (item.url === location.pathname) {
          appStore.addQuickEntryItem({
            title: item.title,
            key: item.url,
          })
        }
        item.children?.forEach((subItem) => {
          if (subItem.url === location.pathname) {
            appStore.addQuickEntryItem({
              title: subItem.title,
              key: subItem.url,
            })
          }
        })
      })
    })
  })

  return (
    <MainApp>
      <MainSider collapsed={collapsed} />
      <MainBody collapsed={collapsed}>
        <MainHeader>
          <div className="header-left">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'menu-trigger',
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </div>
          <div className="header-right">
            <Dropdown overlay={userMenu}>
              <div>
                <Avatar size={32} src={userStore.userinfo.avatar} />
              </div>
            </Dropdown>
          </div>
        </MainHeader>
        <MainTabs
          activeKey={activeKey}
          type="editable-card"
          size="small"
          onEdit={onTabEdit}
          onChange={onTabChange}
          hideAdd
        >
          <TabPane tab="首页" key="/"></TabPane>
          {appStore.quickEntry.map((item) => (
            <TabPane tab={item.title} key={item.key}></TabPane>
          ))}
        </MainTabs>
        <MainContainer>
          {props.children}
          <Loading loading={appStore.subAppLoading}>
            <div id="sub-app" style={{ margin: -8 }}></div>
          </Loading>
        </MainContainer>
      </MainBody>
    </MainApp>
  )
})
