import {
  registerMicroApps,
  start,
  initGlobalState,
  FrameworkLifeCycles,
} from 'qiankun'
import { appStore } from '~/stores'
import { UserinfoProps, PermissionMenuItemProps } from '~/stores/userStore'

import { subApps } from '~/configs/subApps'

export interface SubAppProps {
  userinfo: UserinfoProps
  permissionMenus: PermissionMenuItemProps[]
}

export default function subRegister(props: SubAppProps) {
  const loader = (loading: boolean) => {
    console.log('LOADING %c%s', 'color:green', loading)
    appStore.setSubAppLoading(loading)
  }

  const LifeCycles: FrameworkLifeCycles<{}> = {
    beforeLoad: [
      async (app) => {
        console.log('[LifeCycle] before load %c%s', 'color: green;', app.name)
      },
    ],
    beforeMount: [
      async (app) => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
      },
    ],
    afterUnmount: [
      async (app) => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
      },
    ],
  }

  console.log(props, 'subAppProps')

  registerMicroApps(
    subApps.map((app) => {
      return {
        ...app,
        props,
        loader,
      }
    }),
    LifeCycles
  )

  start({
    // sandbox: {
    //   experimentalStyleIsolation: true,
    //   // strictStyleIsolation: true
    // }
  })
}

export const state = {
  // 页面标题
  title: '',
}

export const actions = initGlobalState(state)

export const {
  onGlobalStateChange,
  setGlobalState,
  offGlobalStateChange,
} = actions
