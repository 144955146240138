import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { subApps } from '~/configs/subApps'

export const useInSubApp = () => {
  const location = useLocation()

  const inSubApp = useMemo(() => {
    return subApps.filter((item) => !!item.activeRule(location)).length > 0
  }, [location])

  return {
    inSubApp,
  }
}
