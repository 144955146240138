import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

interface LoadingProps {
  loading: boolean
}

const LoadingWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
`

const LoadingInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -15px;
  text-align: center;
  .ant-spin-dot {
    font-size: 30px;
  }
`

// 备注：为了不让loading渲染在div dom上
// https://styled-components.com/docs/faqs
// https://github.com/styled-components/styled-components/issues/1198

const Container = styled(({ loading: boolean, ...props }) => (
  <div {...props}>{props.children}</div>
))`
  visibility: ${(props) => (props.loading ? 'hidden' : 'visible')};
`

export const Loading: React.FC<LoadingProps> = ({ children, loading }) => {
  return (
    <React.Fragment>
      {loading && (
        <LoadingWrap>
          <LoadingInner>
            <Spin />
          </LoadingInner>
        </LoadingWrap>
      )}
      <Container loading={loading}>{children}</Container>
    </React.Fragment>
  )
}
