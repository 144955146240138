import H from 'history'
import { isTradeAdmin } from '~/utils'

interface SubAppConfig {
  name: string
  entry: string
  container: string
  activeRule: (location: Location | H.Location) => boolean
}

enum SUBAPP_PREFIX {
  VUE = '/vue',
  BTC8 = '/btc8',
  QKL = '/qkl',
  CN = '/cn',
  BC = '/bc',
  TRADE = '/trade',
  AI = '/ai',
}

function genActiveRule(routerPrefix: string) {
  return (location: Location | H.Location) =>
    location.pathname.startsWith(routerPrefix)
}

const PROD_HOST = isTradeAdmin()
  ? process.env.TRADE_ADMIN_HOST
  : process.env.ONE_ADMIN_HOST

export const subApps: SubAppConfig[] = [
  {
    name: 'vue',
    entry: PROD_HOST ? `${PROD_HOST}:19528` : '//localhost:9528',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.VUE),
  },
  {
    name: 'cn',
    entry: PROD_HOST ? `${PROD_HOST}:19531` : '//localhost:9531',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.CN),
  },
  {
    name: 'btc8',
    entry: PROD_HOST ? `${PROD_HOST}:19529` : '//localhost:9529',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.BTC8),
  },
  {
    name: 'qkl',
    entry: PROD_HOST ? `${PROD_HOST}:19530` : '//localhost:9530',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.QKL),
  },
  {
    name: 'bc',
    entry: PROD_HOST ? `${PROD_HOST}:19532` : '//localhost:9532',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.BC),
  },
  {
    name: 'trade',
    entry: PROD_HOST ? `${PROD_HOST}:19533` : '//localhost:9533',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.TRADE),
  },
  {
    name: 'ai',
    entry: PROD_HOST ? `${PROD_HOST}:19534` : '//localhost:9534',
    container: '#sub-app',
    activeRule: genActiveRule(SUBAPP_PREFIX.AI),
  },
]
