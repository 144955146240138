import { makeAutoObservable, runInAction } from 'mobx'
import * as api from '~/api'
import { randomRange, setToken } from '~/utils'
import CryptoJS from 'crypto-js'

export interface PermissionMenuItemProps {
  url: string
  permis: string
  menu_name: string
  key: string
  title: string
  visible: boolean | string
  menu_type: string
  menu_id: string | number
  order_num: number
  children?: PermissionMenuItemProps[]
}

export interface UserinfoProps {
  avatar: string
  username: string
  user_type: number[]
}

interface LoginProps {
  username: string
  password: string
}

export const getPermissionMenusByTree = (
  data: PermissionMenuItemProps[]
): PermissionMenuItemProps[] => {
  return [...data]
    .sort((a, b) => {
      return a.order_num - b.order_num
    })
    .map((item) => {
      if (item.children?.length) {
        item.children = getPermissionMenusByTree(item.children)
      } else {
        delete item.children
      }

      return {
        ...item,
        key: item.menu_id.toString(),
        title: item.menu_name,
      }
    })
}

export class UserStore {
  constructor() {
    makeAutoObservable(this)
  }

  userinfo: UserinfoProps = {
    avatar: '',
    username: '',
    user_type: [],
  }

  permissionMenus: PermissionMenuItemProps[] = []

  // 保存权限菜单的展开状态
  permissionExpandedRowKeys: number[] = []

  get isAdminUser() {
    return this.userinfo.user_type?.includes(2)
  }

  get permissionKeys() {
    const permissionKeys: (string | number)[] = []
    const getMenuKeys = (menus: PermissionMenuItemProps[]) => {
      menus.forEach((item) => {
        permissionKeys.push(item.permis)
        item.children?.length && getMenuKeys(item.children)
      })
    }
    getMenuKeys(this.permissionMenus)

    return permissionKeys
  }

  login({ username, password }: LoginProps) {
    return api
      .userLogin({
        data: {
          username: username.trim(),
          password: CryptoJS.MD5(password).toString(),
          source: 'web',
          product_code: 'one-admin',
          device_id: randomRange(20, 20),
        },
      })
      .then((res) => {
        runInAction(() => {
          const { data } = res.data

          if (!data.token) {
            throw new Error('err')
          }

          this.userinfo = data.user_info
          setToken(data.token)
        })

        return res
      })
  }

  getUserInfo() {
    return api.getUserInfo().then((res) => {
      runInAction(() => {
        const { data } = res.data
        this.userinfo = data
      })

      return res
    })
  }

  getPermissionMenuTree() {
    return api.getPermissionMenuTree().then((res) => {
      runInAction(() => {
        this.permissionMenus = getPermissionMenusByTree(res.data) || []
      })

      return res
    })
  }

  setPermissionExpandedRowKeys(key: number, isAdd: boolean) {
    if (isAdd) {
      this.permissionExpandedRowKeys.push(key)
    } else {
      const delIndex = this.permissionExpandedRowKeys.findIndex(
        (item) => item === key
      )
      this.permissionExpandedRowKeys.splice(delIndex, 1)
    }
  }
}
