import axios from 'axios'
import { getAuth, getToken, removeToken, isTradeAdmin } from '~/utils'

const getURL = (url: string) => {
  let baseURL = ''
  let newUrl = ''
  switch (url.split('/')[1]) {
    case 'api':
      baseURL =
        (isTradeAdmin() ? process.env.TRADE_API_HOST : process.env.API_HOST) ||
        ''
      newUrl = url.replace('/api', '')
      break
    default:
      newUrl = url
  }

  return {
    baseURL,
    url: newUrl,
  }
}

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    from: 'web',
    'app-code': 'admin',
  },
})

instance.interceptors.request.use(
  async (config) => {
    config.headers['Authorization'] = getAuth()

    const token = getToken()

    if (token) {
      config.headers['gate-token'] = token
    }

    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    console.log(
      'resError',
      error.response && error.response.data,
      error.response && error.response.config.url
    )

    if (error?.response?.data?.code === '20020003') {
      removeToken()
    }

    return Promise.reject((error.response && error.response.data) || {})
  }
)

interface OtherProps {
  headers?: object
}

const api = {
  get(url: string, data?: any, other?: OtherProps) {
    return instance({
      method: 'get',
      baseURL: getURL(url).baseURL,
      url: getURL(url).url,
      headers: other?.headers || {},
      params: data,
    })
  },
  post(url: string, data?: any, other?: OtherProps) {
    return instance({
      method: 'post',
      baseURL: getURL(url).baseURL,
      url: getURL(url).url,
      headers: other?.headers || {},
      data,
    })
  },
  patch(url: string, data?: any, other?: OtherProps) {
    return instance({
      method: 'patch',
      baseURL: getURL(url).baseURL,
      url: getURL(url).url,
      headers: other?.headers || {},
      data,
    })
  },
  put(url: string, data?: any, other?: OtherProps) {
    return instance({
      method: 'put',
      baseURL: getURL(url).baseURL,
      url: getURL(url).url,
      headers: other?.headers || {},
      data,
    })
  },
  delete(url: string, data?: any, other?: OtherProps) {
    return instance({
      method: 'delete',
      baseURL: getURL(url).baseURL,
      url: getURL(url).url,
      headers: other?.headers || {},
      data,
    })
  },
}

export default api
