import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Menu, Layout, Avatar } from 'antd'
import { goto, APP_NAME, isTradeAdmin } from '~/utils'
import { Link, useLocation } from 'react-router-dom'
import { font, textOverflow } from '~/configs/styles'
import styled from 'styled-components'
import { appStore, userStore } from '~/stores'
import { observer } from 'mobx-react'

const { SubMenu } = Menu
const { Sider } = Layout

const isTest = process.env.APP_ENV !== 'online'

const SideBar = styled(Sider)`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    overflow: hidden;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    /* 补丁：复写antd样式 */
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    font-size: 14px;
    line-height: 20px;
    -webkit-font-smoothing: auto;
    font-family: ${font.fontFamily};
    > div {
      overflow-y: auto;
    }
    .logo {
      height: 32px;
      margin: 16px;
      padding: 4px 8px;
      color: #fff;
      font-size: 14px;
      text-align: ${(props) => (props.collapsed ? 'center' : 'left')};
      ${textOverflow};
    }
  }
`

interface SideBarProps {
  collapsed: boolean
}

export const MainSider: React.FC<SideBarProps> = observer((props) => {
  const { collapsed } = props
  const location = useLocation()

  const defaultOpenKeys = useMemo(() => {
    const openKeys: string[] = []

    userStore.permissionMenus.forEach((allMenu) => {
      allMenu.children?.forEach((item) => {
        if (item.url === location.pathname) {
          openKeys.push(allMenu.permis)
        }
        item.children?.forEach((subItem) => {
          if (subItem.url === location.pathname) {
            openKeys.push(allMenu.permis, item.permis)
          }
        })
      })
    })

    return openKeys
  }, [location])

  const [openKeys, setOpenKeys] = useState<string[]>([])

  const onOpenChange = useCallback(
    (keys) => {
      const rootKeys = userStore.permissionMenus.map((menu) => menu.permis)
      const latestOpenKey = keys.find(
        (key: string) => openKeys.indexOf(key) === -1
      )
      if (rootKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys)
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
      }
    },
    [openKeys]
  )

  const onMenuHandle = useCallback((menuItem) => {
    goto(menuItem.menu_name, menuItem.url)
    appStore.addQuickEntryItem({
      title: menuItem.menu_name,
      key: menuItem.url,
    })
  }, [])

  useEffect(() => {
    setOpenKeys(defaultOpenKeys)
  }, [defaultOpenKeys])

  return (
    <SideBar trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <Link
          to="/"
          style={{
            color: '#fff',
          }}
        >
          <Avatar
            size={24}
            shape="square"
            src={
              isTradeAdmin()
                ? require('~/assets/images/common/logo-trade.png')
                : require('~/assets/images/common/logo.png')
            }
            alt=""
          />
          {!collapsed && (
            <span style={{ paddingLeft: '8px' }}>
              {APP_NAME}
              {isTest && (
                <span
                  style={{
                    fontSize: 12,
                    color: '#ccc',
                    verticalAlign: 'top',
                  }}
                >
                  测试站
                </span>
              )}
            </span>
          )}
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        defaultOpenKeys={openKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {/* 备注：支持且仅支持2、3级为菜单 */}
        {userStore.permissionMenus
          .filter((m: any) => Number(m.visible) === 1)
          .map((currentMenu) => {
            return (
              <SubMenu title={currentMenu.menu_name} key={currentMenu.permis}>
                {currentMenu.children
                  ?.filter((m: any) => Number(m.visible) === 1)
                  .map((menu: any) => {
                    if (menu.menu_type === 'C') {
                      return (
                        <SubMenu title={menu.menu_name} key={menu.permis}>
                          {menu.children
                            ?.filter((m: any) => Number(m.visible) === 1)
                            .map((subItem: any) => (
                              <Menu.Item
                                key={subItem.url}
                                onClick={() => onMenuHandle(subItem)}
                              >
                                <span>{subItem.menu_name}</span>
                              </Menu.Item>
                            ))}
                        </SubMenu>
                      )
                    } else {
                      return (
                        <Menu.Item
                          key={menu.url}
                          onClick={() => onMenuHandle(menu)}
                        >
                          <span>{menu.menu_name}</span>
                        </Menu.Item>
                      )
                    }
                  })}
              </SubMenu>
            )
          })}
      </Menu>
    </SideBar>
  )
})
