import React, { useEffect } from 'react'
import { useTitle } from '~/hooks/useTitle'

export const FullLayout: React.FC = (props) => {
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle()
  }, [setTitle])

  return <div>{props.children}</div>
}
