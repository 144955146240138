import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { color } from '~/configs/styles'

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: ${color.primary};
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
`

interface LinkButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  return <Button {...props}>{props.children}</Button>
}
