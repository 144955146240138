import CryptoJS from 'crypto-js'
import { Token } from '~/configs'
import Cookies from 'js-cookie'
import XLSX from 'xlsx'

export function goto(title: string, href: string) {
  window.history.pushState({}, title, href)
}

export function encrypt(plaintText: string, cryptojsKey: string) {
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }
  const key = CryptoJS.enc.Utf8.parse(cryptojsKey)
  const encryptedData = CryptoJS.AES.encrypt(plaintText, key, options)
  const encryptedBase64Str = encryptedData
    .toString()
    .replace(/\//g, '_')
    .replace(/\+/g, '-')

  return encryptedBase64Str
}

export function decrypt(encryptedBase64Str: string, cryptojsKey: string) {
  const vals = encryptedBase64Str.replace(/-/g, '+').replace(/_/g, '/')
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }
  const key = CryptoJS.enc.Utf8.parse(cryptojsKey)
  const decryptedData = CryptoJS.AES.decrypt(vals, key, options)
  const decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
  return decryptedStr
}

export const getAuth = () => {
  const CRYPTOJSKEY = 'WTAHAPPYACTIVITY'

  let timestamp = Math.floor(new Date().getTime() / 1000).toString()
  let plaintText = JSON.stringify({
    appId: '1',
    timestamp,
    serverCode: '0',
  })

  return JSON.stringify({
    secretKeyVersion: 1,
    sign: encrypt(plaintText, CRYPTOJSKEY),
  })
}

export const getToken = () => {
  return Cookies.get(Token) || ''
}

export const setToken = (token: string) => {
  Cookies.set(Token, token, {
    expires: 365,
  })
}

export const removeToken = () => {
  Cookies.remove(Token)
}

export const randomRange = (min: number, max: number) => {
  let returnStr = '',
    range = max ? Math.round(Math.random() * (max - min)) + min : min,
    arr = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]
  for (let i = 0; i < range; i++) {
    let index = Math.round(Math.random() * (arr.length - 1))
    returnStr += arr[index]
  }
  return returnStr
}

// 递归删除空的children
export const rmEmptyChild = (data: any[]): any[] => {
  return data.map((item) => {
    if (item.children?.length) {
      rmEmptyChild(item.children)
    } else {
      delete item.children
    }

    return item
  })
}

export const getFileJson = (file: File | Blob, cb: (data: any) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => cb(reader.result))
  reader.readAsBinaryString(file)
}

export function getBase64(img: File | Blob, cb: (imageUrl: any) => void) {
  const reader = new FileReader()
  reader.addEventListener('load', () => cb(reader.result))
  reader.readAsDataURL(img)
}

export function getAlign(number: number, guideLines: number[]) {
  console.log(number)
  if (number < guideLines[1]) {
    return 0
  } else if (number >= guideLines[1] && number <= guideLines[2]) {
    return 1
  } else {
    return 2
  }
}
interface IExportArrToExcel {
  /** 表格key值 */
  keys: string[]
  /** 表格数据内容 */
  data: Record<string, any>[]
  /** 表头信息 */
  title: string[]
  /** 导出文件名 */
  fileName: string
  /** 自定义宽度 */
  autoWidth?: boolean
}
const jsonToArray = (key: string[], jsonData: any[]) => {
  return jsonData.map((v: any) =>
    key.map((j: string) => {
      return v[j]
    })
  )
}
const setExcelWidth = (ws: XLSX.WorkSheet, data: any[]) => {
  const colWidth = data.map((row: any) =>
    row.map((val: any) => {
      if (val == null) {
        return { wch: 10 }
      } else if (val.toString().charCodeAt(0) > 255) {
        /* 中文处理 */
        return { wch: val.toString().length * 2 }
      } else {
        return { wch: val.toString().length }
      }
    })
  )
  let result = colWidth[0]
  for (let i = 1; i < colWidth.length; i++) {
    for (let j = 0; j < colWidth[i].length; j++) {
      if (result[j]['wch'] < colWidth[i][j]['wch']) {
        result[j]['wch'] = colWidth[i][j]['wch']
      }
    }
  }
  ws['!cols'] = result
}
export const exportArrayToExcel = (tableInfo: IExportArrToExcel) => {
  const { keys, data, title, fileName, autoWidth = true } = tableInfo
  const wb = XLSX.utils.book_new()
  const arr = jsonToArray(keys, data)
  arr.unshift(title)
  const ws = XLSX.utils.aoa_to_sheet(arr)
  if (autoWidth) {
    setExcelWidth?.(ws, arr)
  }
  XLSX.utils.book_append_sheet(wb, ws, fileName)
  XLSX.writeFile(wb, fileName + '.xlsx')
}

export const isTradeAdmin = () => {
  return (
    window?.location?.origin?.indexOf('one-admin.wujiebantu.com') > -1 ||
    process.env.REACT_APP_NAME === 'trade'
  )
}

export const APP_NAME = isTradeAdmin() ? '无界版图后台' : '巴比特大后台'
