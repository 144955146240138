import loadable from '@loadable/component'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

interface AsyncViewProps extends RouteComponentProps {
  component: string
}

export const AsyncView = loadable(
  (props: Partial<AsyncViewProps>) => {
    return props.component ?
      import(`../views/${props.component}`) :
      Promise.resolve(() => (<div></div>))
  }
)
