import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
} from '@apollo/client'

import { getAuth, getToken, isTradeAdmin } from '~/utils'
import { onError } from '@apollo/link-error'

const uri = isTradeAdmin()
  ? process.env.TRADE_GRAPHQL_HOST
  : process.env.GRAPHQL_HOST

const httpLink = new HttpLink({
  uri,
  credentials: 'same-origin',
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: object }) => ({
    headers: getToken()
      ? {
          ...headers,
          from: 'web',
          'app-code': 'admin',
          'gate-token': getToken(),
          authorization: getAuth(),
        }
      : {
          ...headers,
          from: 'web',
          'app-code': 'admin',
          authorization: getAuth(),
        },
  }))

  return forward(operation)
})

const errorLink = onError(
  ({ response, operation, forward, graphQLErrors, networkError }) => {
    console.log(
      response,
      operation,
      forward,
      graphQLErrors,
      networkError,
      'errorLink'
    )
  }
)

const link = from([errorLink, authLink, httpLink])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
