import api from './fetch'
import { SystemCode } from '~/assets/interface'
import { isTradeAdmin } from '~/utils'

interface ApiOptions {
  params?: any
  data?: any
}

// 当前用户
export const userLogin = (options: ApiOptions) => {
  return api.post('/api/account-web/backgroundLogin', options.data)
}

export const getUserInfo = () => {
  return api.get('/api/account-web/unifyGetUserInfo')
}

// 权限-用户
export const getPermissionUserByRole = (options: ApiOptions) => {
  return api.post('/api/permission/getRoleUserList', options.data, {
    headers: {
      system_code: isTradeAdmin() ? SystemCode.COPYRIGHT : '',
    },
  })
}

export const getPermissionUserByInfo = (options: ApiOptions) => {
  return api.post('/api/permission/searchUserInfo', options.data)
}

export const setPermissionUserItemRole = (options: ApiOptions) => {
  return api.post('/api/permission/associateUserRole', options.data)
}

// 权限-菜单
export const getPermissionMenuTree = () => {
  return api.get('/api/permission/buildMenuTreeSelect')
}

export const addPermissionMenuItem = (options: ApiOptions) => {
  return api.post('/api/permission/createMenu', options.data)
}

export const rmPermissionMenuItem = (options: ApiOptions) => {
  return api.delete(`/api/permission/delMenu?menuId=${options.params.menuId}`)
}

export const updatePermissionMenuItem = (options: ApiOptions) => {
  return api.put('/api/permission/updateMenu', options.data)
}

// 权限-角色
export const getPermissionRole = () => {
  return api.get('/api/permission/queryRoleAll')
}

export const rmPermissionRoleItem = (options: ApiOptions) => {
  return api.delete(`/api/permission/delRole?roleId=${options.params.roleId}`)
}

export const addPermissionRoleItem = (options: ApiOptions) => {
  return api.post('/api/permission/createRole', options.data)
}

/** 编辑用户角色信息 */
export const editPermissionRoleInfo = (options: ApiOptions) => {
  return api.put('/api/account/modifyUserInfo', options.data)
}

export const updatePermissionRoleItem = (options: ApiOptions) => {
  return api.put('/api/permission/updateRole', options.data)
}

export const getPermissionRoleItemCheckedKeys = (options: ApiOptions) => {
  return api.get(
    `/api/permission/queryRolePermiMenuTree?roleId=${options.params.roleId}`
  )
}

export const setPermissionRoleItemMenuIds = (options: ApiOptions) => {
  return api.post('/api/permission/associateRoleMenu', options.data)
}

// 用户管理
export const getUserBindInfo = (options: ApiOptions) => {
  return api.get('/api/account-web/backend/getSocialBindInfo', options.data)
}

export const unbindSocialAccount = (options: ApiOptions) => {
  return api.post('/api/account-web/backend/unbindSocialAccount', options.data)
}

export const addVestAccount = (options: ApiOptions) => {
  return api.post('/api/account-web/backgroundRegister', options.data)
}

// 用户头像
export const getAvatarList = (options: ApiOptions) => {
  return api.get('/api/php/admin/avatar/list', options.data)
}

export const updateAvatarStatus = (options: ApiOptions) => {
  return api.post('/api/php/admin/avatar/audit', options.data)
}
