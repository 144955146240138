import { useMemo } from 'react'
import { APP_NAME } from '~/utils'
import { allRoutes } from '~/configs/routes'
import { useInSubApp } from './useInSubApp'

import { useLocation } from 'react-router'

export const useTitle = () => {
  const location = useLocation()
  const { inSubApp } = useInSubApp()

  const mainAppRoutes = useMemo(() => {
    return allRoutes.filter((item) => !!item.title)
  }, [])

  const mainAppTitle = useMemo(() => {
    return (
      mainAppRoutes.filter((item) => item.path === location.pathname)[0]
        ?.title || '404'
    )
  }, [mainAppRoutes, location.pathname])

  const setTitle = (title?: string) => {
    if (inSubApp) {
      title && (document.title = `${title}-${APP_NAME}`)
    } else {
      document.title = `${mainAppTitle}-${APP_NAME}`
    }
  }

  return { setTitle }
}
