import React, { FC } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Route, Switch } from 'react-router-dom'

// 备注，过渡动画会导致组件重复渲染，因为CSSTransition会添加和删除class
// https://github.com/reactjs/react-transition-group/issues/344
// https://github.com/reactjs/react-transition-group/issues/185

export const AnimatedSwitch: FC = (props) => {
  const { children } = props
  return (
    <Route
      render={({ location }) => (
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            classNames={'fade'}
            timeout={500}
            appear
          >
            <Switch location={location}>{children}</Switch>
          </CSSTransition>
        </SwitchTransition>
      )}
    />
  )
}
