import { subApps } from './subApps'

export interface IRouteConfig {
  path: string
  title?: string
  exact?: boolean
  component?: string
}

export interface IAynscRouteConfig extends IRouteConfig {
  key: string
}

export const baseRoutes: IRouteConfig[] = [
  {
    path: '/',
    title: '首页',
    component: 'Dashboard',
    exact: true,
  },
  {
    path: '/404',
    title: '404',
    component: 'NotFound',
  },
  ...subApps.map((item) => ({
    path: `/${item.name}*`,
  })),
]

export const asyncRoutes: IAynscRouteConfig[] = [
  {
    path: '/permission/user',
    title: '用户列表-用户权限',
    component: 'permission/AdminUser',
    key: 'permission.user',
  },
  {
    path: '/permission/role',
    title: '角色列表-用户权限',
    component: 'permission/UserRole',
    key: 'permission.role',
  },
  {
    path: '/permission/permission',
    title: '权限列表-用户权限',
    component: 'permission/UserPermission',
    key: 'permission.permission',
  },
  {
    path: '/user/list',
    title: '用户列表-用户管理',
    component: 'user-manage/UserList',
    key: 'userManage.list',
  },
  {
    path: '/user/avatar',
    title: '头像审核-用户管理',
    component: 'user-manage/UserAvatar',
    key: 'userManage.avatar',
  },
  {
    path: '/tools/sync-data',
    title: '同步数据-工具',
    component: 'tools/SyncData',
    key: 'tools.syncData',
  },
  {
    path: '/tools/invitation',
    title: '邀请函-工具',
    component: 'tools/Invitation',
    key: 'tools.invitation',
  },
  {
    title: 'LogRocket-工具',
    path: '/tools/logrocket',
    component: 'tools/Logrocket',
    key: 'tools.logrocket',
  },
]

export const fullRoutes: IRouteConfig[] = [
  {
    path: '/login',
    title: '登录页',
    component: 'Login',
  },
]

export const allRoutes = [...baseRoutes, ...asyncRoutes, ...fullRoutes]
