import { makeAutoObservable } from 'mobx'

interface QuickEntryProps {
  key: string
  title: string
}

export class AppStore {
  constructor() {
    makeAutoObservable(this)
  }

  mainAppLoading: boolean = false
  subAppLoading: boolean = false

  quickEntry: QuickEntryProps[] = []

  setMainAppLoading(state: boolean) {
    this.mainAppLoading = state
  }

  setSubAppLoading(state: boolean) {
    this.subAppLoading = state
  }

  initQuickEntry() {
    this.quickEntry = JSON.parse(
      localStorage.getItem('quick') || '[]'
    ) as QuickEntryProps[]
  }

  addQuickEntryItem(state: QuickEntryProps) {
    if (
      this.quickEntry.findIndex((item) => item.key === state.key) === -1 &&
      state.key !== '/'
    ) {
      // 备注：只保留5个，后续可调整
      this.quickEntry = [...this.quickEntry, state].slice(-5)
      localStorage.setItem('quick', JSON.stringify(this.quickEntry))
    }
  }

  delQuickEntryItem(state: QuickEntryProps) {
    this.quickEntry = this.quickEntry.filter((item) => item.key !== state.key)
    localStorage.setItem('quick', JSON.stringify(this.quickEntry))
  }
}
