import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import subRegister from '~/utils/subRegister'
import { userStore } from '~/stores'
import { getToken } from '~/utils'

function mainAppRender() {
  ReactDOM.render(<App />, document.getElementById('main-app'))
}

async function allRender() {
  if (getToken()) {
    // 备注：catch是为了应对登录态失效等问题
    await Promise.all([
      userStore.getUserInfo(),
      userStore.getPermissionMenuTree(),
    ])
      .then(() => {
        subRegister({
          userinfo: userStore.userinfo,
          permissionMenus: userStore.permissionMenus,
        })
      })
      .catch(() => {})
  }
  mainAppRender()
}

allRender()

serviceWorker.unregister()
