import React from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom'

import { client } from '~/graphqls'
import { ApolloProvider } from '@apollo/client'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import { AnimatedSwitch } from '~/components/AnimatedSwitch'

import './App.less'

import {
  fullRoutes,
  baseRoutes,
  asyncRoutes,
  IAynscRouteConfig,
} from '~/configs/routes'
import { AsyncView } from '~/components'
import { MainLayout } from '~/layouts/Main'
import { FullLayout } from '~/layouts/Full'
import { userStore } from '~/stores'
import { observer } from 'mobx-react'

const App: React.FC = observer(() => {
  const mainRoutes = [
    ...baseRoutes,
    ...asyncRoutes.filter((item: IAynscRouteConfig) => {
      return userStore.permissionKeys.includes(item.key)
    }),
  ]

  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          {userStore.isAdminUser ? (
            <Switch>
              <Route exact path={mainRoutes.map((r) => r.path)}>
                <MainLayout>
                  <AnimatedSwitch>
                    {mainRoutes.map((r) => (
                      <Route
                        path={r.path}
                        key={r.path}
                        exact={!!r.exact}
                        component={(props: RouteComponentProps) => (
                          <AsyncView {...props} component={r.component} />
                        )}
                      />
                    ))}
                  </AnimatedSwitch>
                </MainLayout>
              </Route>
              <Route exact path={fullRoutes.map((r) => r.path)}>
                <FullLayout>
                  {fullRoutes.map((r) => (
                    <Route
                      path={r.path}
                      key={r.path}
                      exact={!!r.exact}
                      component={(props: RouteComponentProps) => (
                        <AsyncView {...props} component={r.component} />
                      )}
                    />
                  ))}
                </FullLayout>
              </Route>
              <Route path="*" render={() => <Redirect to="/404" />} />
            </Switch>
          ) : (
            <Switch>
              <Route exact path={fullRoutes.map((r) => r.path)}>
                <FullLayout>
                  {fullRoutes.map((r) => (
                    <Route
                      path={r.path}
                      key={r.path}
                      exact={!!r.exact}
                      component={(props: RouteComponentProps) => (
                        <AsyncView {...props} component={r.component} />
                      )}
                    />
                  ))}
                </FullLayout>
              </Route>
              <Route
                path="*"
                render={() => (
                  <Redirect
                    to={`/login?redirect_url=${window.location.pathname}`}
                  />
                )}
              />
            </Switch>
          )}
        </BrowserRouter>
      </ConfigProvider>
    </ApolloProvider>
  )
})

export default App
